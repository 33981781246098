<template>
  <div class="container">
    <div class="row text-center my-5" v-if="loading">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-md-8 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div v-if="!isItemAvailable">
                <div class="container text-center">
                  <i class="fad fa-info-circle fa-3x mb-3 text-primary"></i>
                  <p>This content will become available on <b>{{scheduleItem.available_from | formatDate}}</b></p>
                </div>
              </div>
              <div v-else>
                <h5 class="mb-2">{{scheduleItem.item.title}}</h5>
                <span class="rounded ml-auto text-capitalize py-1 px-2 w-auto"
                      :class="scheduleItem.item.type === 'video' ? 'badge-info' : 'badge-secondary'"
                >
                  {{ scheduleItem.item.label }}
                </span>
                <hr class="my-3"/>
                <div v-if="scheduleItem.item.type==='video'">
                  <div v-if="scheduleItem.item.description">
                    <div class="card bg-light mb-3">
                      <div class="card-body">
                        {{scheduleItem.item.description}}
                      </div>
                    </div>
                  </div>
                  <div v-if="scheduleItem.content">
                    <div class="card bg-light">
                      <div class="card-body">
                        {{scheduleItem.content}}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>No content has been provided by the practitioner for this item yet.</p>
                  </div>
                </div>
                <div v-if="scheduleItem.item.type==='form'">
                  <!-- Form stuff -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loading: true,
      scheduleItem: null,
    };
  },
  methods: {
    fetchScheduleItem() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/fetch-online-programme-item",
          {
            client_id: this.user.id,
            online_programme_schedule_item_id: this.$route.params.id
          }
      ).then(({ data }) => {
        this.scheduleItem = data;
        this.loading = false;
      });
    },

  },
  computed: {
    isItemAvailable() {
      return moment(this.scheduleItem.available_from).isBefore();
    },
    user() {
      return this.$store.user;
    }
  },
  mounted() {
    this.fetchScheduleItem();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL [@] HH:mm A");
    },
  }
};
</script>

<style>
</style>
